<template>
  <div style="display:flex;justify-content:flex-end;padding:5px;flex-wrap:wrap" :style="{'flex-direction':config.direction||'row'}">
    <template v-for="i in buttons">
      <template v-if="!i.disable">
        <template v-if="i.type=='dropdown'">
          <div class="btn-group dropup" style="pointer-events:auto">
            <button type="button" class="btn btn-sm bm dropdown-toggle" :class="i.class||'btn-default'" :title="i.title" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span v-if="i.title">{{i.title}}</span><i v-else class="fa fa-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu  dropdown-menu-right">
              <button type="button" v-for="c in i.childs" class="dropdown-item" :class="c.class" @click.prevent="$emit(c.name);doTarget(c.event)">{{c.title}}<span v-if="c.badge" class="badge badge-pill" :class="c.badgeClass||'badge-primary'">{{publicData[c.badge]}}</span></button>
            </div>
          </div>
        </template>
        <template v-else>
          <button type="button" class="btn btn-sm bm" :class="i.class||'btn-default'" style="pointer-events:auto" :title="i.title" :disabled="publicData[i.disabled]" @click.prevent="$emit(i.name);doTarget(i.event)">
            <i v-if="i.iconClass" :class="i.iconClass"></i>
            {{i.title}}
            <span v-if="i.badge" class="badge badge-pill" :class="i.badgeClass||'badge-primary'">{{publicData[i.badge]}}</span>
          </button>
        </template>
      </template>
    </template>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        showMoreIcon: true,
        htmltest: null
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    watch: {
    },
    computed: {
      buttons() {
        return { ...this.config.buttons }
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          doTarget: this.doTarget,
        }
      })
    },
    destroyed() {
    },
    methods: {
      doTarget(e) {
        this.getFunc(e)()
      },
    },
  }
</script>
<style scoped>
  .bm {
    margin: 0.125rem
  }

  .trans {
    background-color: transparent;
    border-color:transparent;
  }
</style>
